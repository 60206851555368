import Submit from "components/submit";
import Input from "components/form/input";

import * as sessionsApi from "api/sessions";

import clsx from "clsx";
import { loadScript } from "utils/browser";
import { FiChevronDown } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";

import { useState } from "react";
import { useNotifications } from "hooks/notifications";
import { useMounted } from "hooks/react";
import { useForm } from "react-hook-form";

declare global {
  interface Window {
    fbLoaded: any;
    fbAsyncInit: any;
    FB: any;
  }
}

const fbSdkUrl = "https://connect.facebook.net/en_US/sdk.js";
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

export default function FacebookLoginButton(props) {
  const { text, className } = props;
  const { onClick, onSuccess, onToggleEmail } = props;

  const { register, handleSubmit, formState, watch } = useForm();

  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [showEmailInput, setShowEmailInput] = useState(false);

  const { setNotification } = useNotifications();

  useMounted(() => {
    initializeSdk().then(() => {
      setLoading(false);
    });
  });

  function initializeSdk() {
    return loadScript(fbSdkUrl, {
      async: true,
      defer: true,
      crossOrigin: "anonymous"
    }).then(() => {
      const FB = window.FB;
      FB.init({
        appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v12.0"
      });
    });
  }

  function handleClick() {
    onClick && onClick();
    showEmailInput ? getOrCreateUser(accessToken) : requestFacebookLogin();
  }

  function requestFacebookLogin() {
    setLoading(true);
    const FB = window["FB"];
    FB.login(
      (response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          setAccessToken(accessToken);
          getOrCreateUser(accessToken);
        } else {
          handleRefusal();
        }
      },
      { scope: "email" }
    );
  }

  function getOrCreateUser(accessToken) {
    setLoading(true);
    const email = showEmailInput ? watch("email") : null;
    sessionsApi
      .create({ accessToken, provider: "facebook", email: email })
      .then((response) => {
        const data = response.body.data;
        const { user, token } = data;
        const facebookId = user.oauthId;
        onSuccess({ user, token, attributes: { oauth_id: facebookId } });
      })
      .catch((error) => {
        if (error.statusCode !== 422) throw error;

        setLoading(false);
        handleError(error);
      });
  }

  function handleError(error) {
    const errors = error.responseBody.errors;

    if (!showEmailInput && isNoEmailError(errors)) return handleEmptyEmail();

    if (errors.name || errors.email) return notifyError(errors);

    throw error;
  }

  function handleEmptyEmail() {
    setLoading(false);
    setShowEmailInput(true);
    onToggleEmail && onToggleEmail(false);
  }

  function notifyError(errors) {
    const label = errors.name ? "Nome" : "E-mail";
    const attributeErrors = errors.name ? errors.name : errors.email;

    setNotification({
      title: "A autenticação falhou",
      text: `${label} ${attributeErrors.join(", ")}`,
      type: "error"
    });
  }

  function isNoEmailError(errors) {
    if (!errors.email) return false;

    return errors.email[0].indexOf("em branco") !== -1;
  }

  function handleRefusal() {
    setLoading(false);
    setNotification({
      type: "error",
      title: "Login recusado",
      text: "Não foi possível logar porque as permissões não foram aceitas"
    });
  }

  function handleEmailSubmit() {
    getOrCreateUser(accessToken);
  }

  function hideEmailInput() {
    setShowEmailInput(false);
    onToggleEmail && onToggleEmail(true);
  }

  return (
    <>
      {showEmailInput && (
        <form onSubmit={handleSubmit(handleEmailSubmit)} className="mb-2">
          <header className="flex items-center justify-center mb-4 mt-0 mx-auto md:mb-8">
            <div
              className="mr-2 cursor-pointer transform rotate-90"
              onClick={hideEmailInput}
            >
              <FiChevronDown />
            </div>
            <p className="my-0 md:text-2xl md:leading-9">
              Criar conta com o Facebook
            </p>
          </header>

          <Input
            disabled={loading}
            label="E-mail"
            name="email"
            type="email"
            autofocus={true}
            register={register}
            formState={formState}
            validations={{ required: true, pattern: emailRegex }}
          />
        </form>
      )}

      <div className="fb-wrapper w-full">
        <Submit
          className={clsx("btn btn-facebook !min-h-[48px] w-full", {
            [className]: className
          })}
          loading={loading}
          onClick={handleClick}
        >
          <FaFacebookF className="mr-2" fill="white" />
          {text}
        </Submit>
      </div>

      <style jsx>
        {`
          .fb-wrapper :global(.btn-facebook) {
            box-shadow: var(--btn-shadow);
            background-color: #4267b2;
            color: #fff;
            transition: background-color 0.1s ease-in;
          }

          .fb-wrapper :global(.btn-facebook span) {
            color: #fff;
          }

          .fb-wrapper :global(.btn-facebook:disabled),
          .fb-wrapper :global(.btn-facebook.disabled) {
            background-color: #869dcd;
            color: #eee;
          }
        `}
      </style>
    </>
  );
}
