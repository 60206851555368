import { useState, useEffect } from "react";

import clsx from "clsx";

const errorMessages = {
  required: () => "não pode ficar em branco",
  pattern: () => "não está no formato correto",
  min: (min) => `deve ser maior que ${min}`,
  minLength: (min) => `deve ter pelo menos ${min} caracteres`
};

interface InputProps {
  name: string;
  label?: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  autofocus?: boolean;
  className?: string;
  inputClassName?: string;
  formState?: Record<string, any>;
  apiErrors?: Record<string, string>;
  onChange?: (value: any) => void;
  maxLength?: number;
  register?: any;
  validations?: any;
  options?: any;
  min?: number;
  max?: number;
}

export default function Input(props: InputProps): JSX.Element {
  const { name, label, type = "text", options, validations, autofocus } = props;
  const { register, formState } = props;
  const { className, inputClassName } = props;
  const { onChange } = props;
  const { min, max } = props;

  const [value, setValue] = useState(props.value);

  const [errorMessage, setErrorMessage] = useState(null);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);

  const apiErrors = props.apiErrors || {};
  const hasError = !!(errorMessage || apiErrorMessage);

  useEffect(() => {
    const errors = formState?.errors || {};
    const error = errors[name] || apiErrors[name];
    if (!error) return setErrorMessage(null);

    const errorType = error.type;
    const message =
      errorType &&
      (error.message || errorMessages[errorType](validations[errorType]));
    if (errorType && !message) throw new Error("Error message not defined");

    setApiErrorMessage(apiErrors && apiErrors[name] && apiErrors[name][0]);
    setErrorMessage(message);
  }, [formState, name, validations, apiErrors]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function handleInput(event) {
    const element = event.currentTarget as HTMLInputElement;
    if (!register) setValue(element.value);
    if (!onChange) return;
    onChange(element.value);
  }

  return (
    <div className={`w-full form-control mb-4 last:mb-0 ${className}`}>
      {label && (
        <label htmlFor={name} className="mb-2">
          {label}
        </label>
      )}

      {(type === "textarea" && (
        <textarea
          id={name}
          className={clsx(
            "px-3 w-full h-48 rounded-md transition",
            inputClassName,
            {
              "border border-error": hasError
            }
          )}
          onInput={handleInput}
          value={value}
          autoFocus={autofocus}
          disabled={props.disabled}
          {...options}
          {...((register && register(name, validations || {})) || {})}
        />
      )) || (
        <input
          id={name}
          type={type}
          className={clsx(
            "px-3 w-full h-11 rounded-md transition",
            inputClassName,
            {
              "border border-error": hasError
            }
          )}
          onInput={handleInput}
          value={value}
          min={min}
          max={max}
          autoFocus={autofocus}
          disabled={props.disabled}
          {...options}
          {...((register && register(name, validations || {})) || {})}
        />
      )}

      <ErrorMessages
        errorMessage={errorMessage}
        apiErrorMessage={apiErrorMessage}
      />
    </div>
  );
}

function ErrorMessages(props) {
  const { errorMessage, apiErrorMessage } = props;

  return (
    <>
      {errorMessage && (
        <span className="block last:mb-0 mb-1 text-error">{errorMessage}</span>
      )}
      {apiErrorMessage && (
        <span className="block text-error">{apiErrorMessage}</span>
      )}
    </>
  );
}
