import Submit from "components/submit";
import Cookies from "js-cookie";
import Image from "next/image";

import * as sessionsApi from "api/sessions";

import clsx from "clsx";
import { loadScript } from "utils/browser";

import { useState } from "react";
import { useNotifications } from "hooks/notifications";
import { useMounted } from "hooks/react";

declare global {
  interface Window {
    google: any;
    googleLoaded: any;
  }
}

const googleSdkUrl = "https://accounts.google.com/gsi/client";

interface GoogleLoginButtonProps {
  text: string;
  className?: string;
  onClick?: () => void;
  onSuccess?: ({
    user,
    token,
    attributes
  }: {
    user: any;
    token: string;
    attributes: any;
  }) => void;
}

export default function GoogleLoginButton(
  props: GoogleLoginButtonProps
): JSX.Element {
  const { text, className } = props;
  const { onClick, onSuccess } = props;

  const [loading, setLoading] = useState(true);

  const { setNotification } = useNotifications();

  useMounted(() => {
    initializeSdk().then(() => {
      setLoading(false);
    });
  });

  function initializeSdk() {
    return loadScript(googleSdkUrl, {
      async: true,
      defer: true
    }).then(() => {
      window.google.accounts.id.initialize({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
        callback: handleGoogleResponse,
        use_fedcm_for_prompt: true
      });
    });
  }

  function handleGoogleResponse(response) {
    if (response.credential) {
      const accessToken = response.credential;
      getOrCreateUser(accessToken);
    } else {
      handleRefusal();
    }
  }

  function handleClick() {
    onClick && onClick();
    requestGoogleLogin();
  }

  function requestGoogleLogin(retry = false) {
    setLoading(true);
    window.google.accounts.id.prompt((notification) => {
      if (notification.isSkippedMoment()) return setLoading(false);

      if (notification.isNotDisplayed() && !retry) {
        Cookies.remove("g_state");
        requestGoogleLogin(true);
      }

      if (notification.getDismissedReason()) {
        const reason = notification.getDismissedReason();
        if (reason === "credential_returned") {
          return;
        }
        setLoading(false);
        handleRefusal();
      } else if (notification.getMomentType()) {
        const type = notification.getMomentType();
        if (type !== "skipped") return;

        setLoading(false);
        handleRefusal();
      }
    });
  }

  function getOrCreateUser(accessToken) {
    setLoading(true);
    sessionsApi
      .create({ accessToken, provider: "google" })
      .then((response) => {
        const data = response.body.data;
        const { user, token } = data;
        const googleId = user.oauthId;
        onSuccess({ user, token, attributes: { oauth_id: googleId } });
      })
      .catch((error) => {
        if (error.statusCode !== 422) throw error;

        setLoading(false);
        handleError(error);
      });
  }

  function handleError(error) {
    const errors = error.responseBody.errors;

    if (errors.name || errors.email) return notifyError(errors);

    throw error;
  }

  function notifyError(errors) {
    const label = errors.name ? "Nome" : "E-mail";
    const attributeErrors = errors.name ? errors.name : errors.email;

    setNotification({
      title: "A autenticação falhou",
      text: `${label} ${attributeErrors.join(", ")}`,
      type: "error"
    });
  }

  function handleRefusal() {
    setLoading(false);
    setNotification({
      type: "error",
      title: "Login recusado",
      text: "Não foi possível logar porque as permissões não foram aceitas"
    });
  }

  return (
    <>
      <div className="google-wrapper rounded-[10px] w-full border border-gray-500">
        <Submit
          className={clsx("btn btn-google !min-h-[48px] w-full space-x-2", {
            [className]: className
          })}
          loading={loading}
          onClick={handleClick}
          data-use_fedcm="true"
        >
          <Image src="/images/google.svg" alt="Google" width={18} height={18} />

          <span>{text}</span>
        </Submit>
      </div>

      <style jsx>
        {`
          .google-wrapper :global(.btn-google) {
            box-shadow: var(--btn-shadow);
            transition: background-color 0.1s ease-in;
          }

          .google-wrapper :global(.btn-google:hover) {
            background-color: #fafafa;
          }
        `}
      </style>
    </>
  );
}
